import React from 'react'
import { Box, Typography } from '@mui/material'

export const CustomFooter = (props) => {
    return (
        <Box sx={{ p: 1, display: 'flex', gap: "10px", justifyContent: "space-around", borderTop: "1px solid rgba(0,0,0,.2)" }}>
            <Typography>Total : {parseFloat(props.total_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
            <Typography>Success : {parseFloat(props.success).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
            <Typography>Total {props.flag ? "Paid" : "Pending"} : {parseFloat(props.paid).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
        </Box>
    )
}

