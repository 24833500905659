import React from 'react'
import img_404 from '../assets/images/404.png';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const _404 = () => {
    const navigate = useNavigate();

    return (
        <>
            <Box sx={{ textAlign: "center", height: "100%", marginTop: "-10px" }}>
                <img src={img_404} height="80%" /><br></br><br></br>
                <Button onClick={() => navigate(-1)} variant="outlined">Go Back</Button>
            </Box>
        </>
    )
}

export default _404