import { Box, Paper, Button, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { Print } from '@mui/icons-material';
import axios from 'axios';
import { useMycontext } from '../global/MyContext';
import { useSnackbar } from 'notistack';
import Logo from "../../assets/images/logo.png";

const BusinessBill = ({ date, associate, business }) => {
    const [subtotal, setSubTotal] = useState("");
    const [associateDetails, setAssociateDetails] = useState([]);
    const componentRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const { apiUrl } = useMycontext();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getAssociateDetails = () => {
        axios.get(apiUrl + 'associate/getone?id=' + associate.associate_id)
            .then(function (response) {
                if (response.data.status) {
                    setAssociateDetails(response.data.data);
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        let total = 0;
        business.forEach((item) => {
            total = total + parseFloat(item.com_amount)
        });
        setSubTotal(total);
        getAssociateDetails();
    }, [])


    return (
        <>
            <Box sx={{ textAlign: "center" }}>
                <Button onClick={handlePrint} startIcon={<Print />}>Print</Button>
            </Box>
            <Box ref={componentRef} sx={{ padding: '20px 30px', minHeight: "100vh", display: 'flex', justifyContent: 'space-between', flexDirection: 'column', }}>
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='h6'>Name &nbsp; &nbsp; : {`${associateDetails.first_name} ${associateDetails.last_name} (${associateDetails.associate_id})`}</Typography>
                            <Typography variant='h6'>{`Address : ${associateDetails.permanent_address}`}</Typography>
                            <Typography variant='h6'>{`${associateDetails.city}, ${associateDetails.state}, ${associateDetails.country} - ${associateDetails.pincode}`}</Typography>
                        </Box>
                        <Typography variant='h6' sx={{ textAlign: 'right' }}>Date: <span>{dayjs().format('DD/MM/YYYY')}</span></Typography>
                    </Box>
                    <Box>
                        <Box>
                            <Typography variant='h5' sx={{ textAlign: 'center', margin: "10px 0px" }}>EBC Statement {dayjs(date).format('MMMM YYYY') !== "Invalid Date" ? "of " + dayjs(date).format('MMMM YYYY') : ""}</Typography>
                        </Box>
                        <TableContainer component={Box}>
                            <Table sx={{ border: "solid 1px #cfcfcf" }} size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "600" }}>S.No</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }}>Booking ID</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }}>Customer Name</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }}>Project Name</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }}>Plot</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }}>Txn Date</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }} align='center'>Amount</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }} align='center'>Status</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }} align='center'>EBV</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }} align='center'>Percent</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }} align="right">EBC</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {business.map((item, index) => (
                                        <TableRow>
                                            <TableCell>{index + 1}.</TableCell>
                                            <TableCell>{item?.booking_no}</TableCell>
                                            <TableCell>{item?.customer_first_name + " " + item?.customer_last_name}</TableCell>
                                            <TableCell>{item.project_name}</TableCell>
                                            <TableCell>{item.pn_no}</TableCell>
                                            <TableCell>{dayjs(item?.txn_date).format('DD/MMM/YYYY')}</TableCell>
                                            <TableCell align='center'>{item?.paid_amount}</TableCell>
                                            <TableCell align='center' sx={{ textTransform: "capitalize" }}>{item?.status}</TableCell>
                                            <TableCell align='center'>{parseFloat(item?.paid_amount * (item?.ebv / 100)).toFixed(2)}</TableCell>
                                            <TableCell align='center'>{item?.com_percent}</TableCell>
                                            <TableCell align="right">{item?.com_amount}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box sx={{ maxWidth: "100%", display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ minWidth: "50%" }}></Box>
                        <TableContainer component={Box}>
                            <Table sx={{ minWidth: "50%", border: "solid 1px #cfcfcf", borderTop: "none" }} aria- label="simple table" size='small'>
                                {/* <TableRow>
                                    <TableCell sx={{ fontWeight: "600" }}>Subtotal</TableCell>
                                    <TableCell align="right">{parseFloat(subtotal).toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "600" }}>Tax (%)</TableCell>
                                    <TableCell align="right">-</TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "600" }}>Total</TableCell>
                                    <TableCell align="right">{parseFloat(subtotal).toFixed(2)}</TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: '20px' }}>
                        <Box sx={{ flex: .3, textAlign: "center", marginTop: "50px" }}>
                            <hr></hr>
                            <Typography>Authorised Signature</Typography>
                        </Box>
                    </Box>
                    <hr style={{ border: '1px solid black', margin: "5px 0px" }}></hr>
                    <Box sx={{ textAlign: "center" }}>
                        <Typography variant='p'>Address: Plot No A-09, 4th Floor, Above TilakRam Restaurent, J.K Tower, Shubh Business Zone, In Front of - People's Hospital Campus Bhanpur, Bhopal - 462037</Typography>
                    </Box>
                </Box>
            </Box >
        </>
    )
}



export default BusinessBill