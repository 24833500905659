import React from 'react'
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
export const LogoLarge = () => {
    return (
        <Link to='/'><Box sx={{ textAlign: "center", lineHeight: "10px" }}>
            <Typography variant='h2' color='primary'>Real Estate</Typography>
            <Typography variant='p'></Typography>
        </Box ></Link>
    )
}

export const LogoSmall = () => {
    return (
        <Box sx={{ textAlign: "center", padding: "6px 0px" }}>
            <Link to='/'><Typography variant='h3' color='primary' sx={{ fontWeight: 600 }}>Real<br />Estate</Typography></Link>
        </Box>
    )
}