import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";


const CardBox = ({ title, number, subtitle, bgColor, txtColor, to }) => {
  return (
    <Card sx={{ backgroundColor: bgColor, flex: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", padding: "16px 24px" }}>
        <Typography variant='h4'>{title}</Typography>
        <Typography variant='h1'>{number}</Typography>
        <Link to={to}><Typography sx={{ color: txtColor }}>{subtitle}</Typography></Link>
      </Box>
    </Card>
  );
}
export default CardBox;
