import React, { useEffect, useState } from 'react'
import { Box, Paper, FormControl, Autocomplete, Button, Modal, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import clsx from "clsx";
import dayjs from 'dayjs';
import Header from '../../components/header'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { setHiddenColumn } from '../../store/globalSlice';
import { useMycontext } from '../../components/global/MyContext';
import { CustomFooter } from './customfooter';
import { CurrencyRupee, Print, Edit, Cancel, TableChart } from '@mui/icons-material';
import BusinessBill from '../../components/invoice/businessbill';

const Payment = () => {

    const columns = [
        {
            field: 'id',
            headerName: 'S.No',
            filterable: false,
            width: 30,
        },
        {
            field: 'associate_id',
            headerName: 'Associate ID',
            minWidth: 120
        },
        {
            field: 'full_name',
            headerName: 'Associate Name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            minWidth: 150,
            flex: 1,
            valueGetter: (params) =>
                `${params.row.first_name || ''} ${params.row.last_name || ''}`,
        },
        {
            field: 'booking_no',
            headerName: 'B.ID',
            width: 50,
        },
        {
            field: 'project_name',
            headerName: 'Project Name',
            width: 170,
        },
        {
            field: 'pn_no',
            headerName: 'Plot No',
            width: 60,
            type: "number"
        },
        {
            field: 'customer_full_name',
            headerName: 'Customer Name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            minWidth: 160,
            flex: 1,
            valueGetter: (params) =>
                `${params.row.customer_first_name || ''} ${params.row.customer_last_name || ''}`,
        },
        {
            field: 'txn_date',
            headerName: 'TXN Date',
            minWidth: 120,
            valueGetter: (params) =>
                `${dayjs(params.row.txn_date).format('DD-MMM-YYYY')}`,
        },
        {
            field: "payment_mode",
            headerName: 'Mode',
            maxWidth: 80,
        },
        {
            field: 'paid_amount',
            headerName: 'Amount(₹)',
            minWidth: 120,
            type: "number",
            valueGetter: (params) =>
                `${parseFloat(params.row.paid_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
        },
        {
            field: 'ebv',
            headerName: 'EBV(₹)',
            minWidth: 120,
            type: "number",
            valueGetter: (params) =>
                `${parseFloat(params.row.paid_amount * (params.row.ebv / 100)).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
        },
        {
            field: 'com_percent',
            headerName: 'Percent',
            minWidth: 70,
            type: "number",
            valueGetter: (params) =>
                `${params.row.com_percent}%`,
        },
        {
            field: 'com_amount',
            headerName: 'Commision(₹)',
            minWidth: 120,
            type: "number",
            valueGetter: (params) =>
                `${parseFloat(params.row.com_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            cellClassName: (params) => {
                if (params.value === null) {
                    return "";
                }
                return clsx("super-app", {
                    success: params.value === "success",
                    cancelled: params.value === "cancelled",
                    completed: params.value === "paid",
                });
            }
        },
        {
            field: 'recieved_date',
            headerName: 'Recieved Date',
            width: 110,
            valueGetter: (params) =>
                `${params.row.recieved_date !== null ? dayjs(params.row.recieved_date).format('DD-MMM-YYYY') : "Not Recieved"}`,
        },
        {
            field: "actions",
            type: "actions",
            width: 30,
            getActions: (cell) => [
                <GridActionsCellItem
                    icon={<CurrencyRupee color='success' fontSize='small' />}
                    label="Pay"
                    onClick={() => {
                        handlePOpen(cell.row.bid);
                        setSelectionModel(cell.row.bid);
                    }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<Cancel color='error' />}
                    label="Cancel"
                    onClick={() => { handlePClose() }}
                    showInMenu
                />,
            ],
        },
    ];

    const dispatch = useDispatch();
    const props = useOutletContext();
    const { enqueueSnackbar } = useSnackbar();
    const { apiUrl } = useMycontext();
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(dayjs().subtract(1, 'month'));
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [selectionModel, setSelectionModel] = useState('');
    const hiddenColumn = useSelector((state) => state.global.hiddenColumn);
    const [rows, setRows] = useState([]);
    const [associate, setAssociate] = useState([]);
    const [associateId, setAssociateId] = useState(null);
    const [recievedDate, setRecievedDate] = useState(dayjs().format('YYYY-MM-DD'));

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        if (rows.length == 0) {
            enqueueSnackbar("Transaction not found", { variant: "error" });
            return;
        }
        if (associateId == null) {
            enqueueSnackbar("Please Select One Associate.", { variant: "error" });
            return;
        }
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const [popen, setPOpen] = useState(false);
    const handlePOpen = (id) => {
        if (rows.length == 0) {
            enqueueSnackbar("Transaction not found.", { variant: "error" });
            return;
        }
        if (associateId == null) {
            enqueueSnackbar("Please Select One Associate.", { variant: "error" });
            return;
        }
        const hasError = rows.some(row => row.status == "success");
        if (!hasError) {
            enqueueSnackbar("Does not find any successfully Business.", { variant: "error" });
            return;
        }
        setSelectionModel(id);
        setPOpen(true);
    }
    const handlePClose = () => setPOpen(false);

    const getBusiness = () => {
        setLoading(true);
        axios.get(apiUrl + "business/get?id=" + associateId?.associate_id + "&date=" + dayjs(date).add(1, 'month').format('YYYY-MM-DD'))
            .then(function (response) {
                if (response.data.status) {
                    setRows(response.data.data.map((row, index) => ({ ...row, bid: row.id, id: index + 1 })));
                    setAssociate(extractAssociateDetails(response.data.data));
                }
                else {
                    setRows([]);
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    function extractAssociateDetails(data) {
        let uniqueAssociateDetails = [];
        data.forEach(transaction => {
            let associateId = transaction.associate_id;
            let firstName = transaction.first_name;
            let lastName = transaction.last_name;
            if (!uniqueAssociateDetails.find(item => item.associate_id === associateId)) {
                uniqueAssociateDetails.push({ associate_id: associateId, first_name: firstName, last_name: lastName });
            }
        });
        uniqueAssociateDetails.sort((a, b) => {
            return a.associate_id.localeCompare(b.associate_id);
        });
        return uniqueAssociateDetails;
    }

    const getBusinessData = (data) => {
        let total = {
            "total_amount": 0,
            "success": 0,
            "paid": 0,
            "flag": true
        };

        data.forEach((item) => {
            if (item.status !== 'cancelled') total['total_amount'] = total['total_amount'] + parseFloat(item.com_amount);
            if (item.status == 'success') total['success'] = total['success'] + parseFloat(item.com_amount);
            if (item.status == 'paid') total['paid'] = total['paid'] + parseFloat(item.com_amount)
        });

        return total;
    }

    const handlePayment = () => {

        const formData = new FormData();
        formData.append('id', associateId.associate_id);
        formData.append('date', dayjs(date).format('YYYY-MM-DD'));
        formData.append('recieved', recievedDate);
        formData.append('bid', selectionModel);

        axios.post(selectionModel !== null ? apiUrl + 'business/payone' : apiUrl + 'business/payment', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    getBusiness();
                    handlePClose();
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function downloadCSV(rows, columns) {
        const newData = rows.map((rows) => {
            // Format the date and time fields
            const full_name = rows.first_name + " " + rows.last_name;
            const customer_full_name = rows.customer_first_name + " " + rows.customer_last_name;
            const txn_date = dayjs(rows.txn_date).format('DD-MMM-YYYY');
            const recieved_date = dayjs(rows.recieved_date).format('DD-MMM-YYYY');
            const ebv = parseFloat(rows.paid_amount * (rows.ebv / 100)).toFixed(2);
            // Return a new object with the formatted fields
            return {
                ...rows,
                full_name: full_name,
                customer_full_name: customer_full_name,
                txn_date: txn_date,
                recieved_date: recieved_date,
                ebv: ebv
            };
        });

        const csvContent =
            'data:text/csv;charset=utf-8,' +
            columns.map((column) => column.headerName).join(',') +
            '\n' +
            newData
                .map((row) =>
                    columns.map((column) => row[column.field]).join(',')
                )
                .join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', associateId ? associateId?.associate_id + '-' + associateId.first_name + ' ' + associateId.last_name + '.csv' : dayjs(date).format('MMMM-YYYY') + '-data.csv');
        document.body.appendChild(link);
        link.click();
    }

    useEffect(() => {
        getBusiness();
    }, [date, associateId]);

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: { xs: "", md: "center" }, gap: "10px", flexDirection: { xs: "column", md: "row" } }}>
                    <Header title="Associate Payment" />
                    <Box sx={{ display: "flex", gap: "10px", flexDirection: { xs: "column-reverse", md: "row" } }}>
                        <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: { xs: "column-reverse", md: "row" }, justifyContent: "center", gap: "10px" }}>
                            <FormControl variant='outlined' sx={{ minWidth: "40ch" }}>
                                <Autocomplete
                                    fullWidth
                                    autoComplete
                                    includeInputInList
                                    size='small'
                                    value={associateId}
                                    onInputChange={(event, newInputValue) => {
                                        setAssociateId(newInputValue);
                                    }}
                                    onChange={(event, newValue) => {
                                        setAssociateId(newValue);
                                    }}
                                    options={associate.map((option, index) => ({ ...option, label: `${index + 1}. ${option.first_name} ${option.last_name} : ${option.associate_id}` }))}

                                    renderInput={(params) =>
                                        <TextField {...params}
                                            variant="outlined"
                                            sx={{ textTransform: "capitalize" }}
                                            label="Associate ID"
                                            name="Associate ID"
                                        />}
                                />
                            </FormControl>
                            <Box sx={{ display: "flex", gap: "10px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        sx={{ maxWidth: "190px" }}
                                        value={date}
                                        onChange={(value) => setDate(value)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                size: "small",
                                            }
                                        }}
                                        label="Select Month"
                                        views={['month', 'year']}
                                    />
                                </LocalizationProvider>
                                <Button sx={{ minWidth: "80px" }} size='small' variant='contained' color='success' startIcon={<CurrencyRupee />} onClick={() => handlePOpen(null)}>Pay</Button>
                                <Button sx={{ minWidth: "70px" }} size='small' variant='contained' color='success' startIcon={<TableChart />} onClick={() => { downloadCSV(rows, columns) }}>Export</Button>
                                <Button sx={{ minWidth: "90px" }} size='small' variant='contained' startIcon={<Print />} onClick={handleOpen}>Print</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box >
                <Box sx={{
                    height: { xs: "calc(100vh - 220px)", md: "calc(100vh - 140px)" },
                    width: { xs: "calc(100vw - 20px)", md: props.collapsed ? "calc(100vw - 105px)" : "calc(100vw - 270px)" },
                    marginTop: "10px",
                    "& .super-app.success": {
                        "& .MuiDataGrid-cellContent": {
                            textAlign: "center",
                            minWidth: "80px",
                            border: "solid 1px lightGreen",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            color: "#fff",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            backgroundColor: "green",
                            textTransform: "capitalize"
                        }
                    },
                    "& .super-app.completed": {
                        "& .MuiDataGrid-cellContent": {
                            textAlign: "center",
                            minWidth: "80px",
                            border: "solid 1px lightgreen",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            color: "#fff",
                            backgroundColor: "green",
                            textTransform: "capitalize"
                        }
                    },
                    "& .super-app.cancelled": {
                        "& .MuiDataGrid-cellContent": {
                            textAlign: "center",
                            minWidth: "80px",
                            border: "solid 1px orange",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            color: "#fff",
                            backgroundColor: "red",
                            textTransform: "capitalize"
                        }
                    },
                }}>
                    <DataGrid
                        loading={loading}
                        checkboxSelection={false}
                        rows={rows}
                        columns={columns}
                        onPaginationModelChange={(params) => setPage(params.page)}
                        hideFooterSelectedRowCount={true}
                        pageSizeOptions={[100]}
                        initialState={{
                            columns: {
                                columnVisibilityModel: hiddenColumn.payload
                            },
                        }}
                        slots={{
                            footer: CustomFooter,
                        }}
                        slotProps={{
                            footer: getBusinessData(rows),
                        }}
                        onColumnVisibilityModelChange={(params) => { dispatch(setHiddenColumn(params)) }}
                        rowCount={rowCount}
                        paginationMode="server"
                    />
                </Box>
            </Box>

            <Modal
                sx={{ overflowX: "hidden" }}
                open={popen}
                onClose={handlePClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: 350,
                    padding: "10px 15px"
                }}>
                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "40px", alignItems: "center" }}>
                        <TextField
                            fullWidth
                            variant="standard"
                            type="date"
                            label="Payment Date"
                            value={recievedDate}
                            onChange={(e) => setRecievedDate(e.target.value)}
                        />
                        <Button color='success' variant='contained' startIcon={<CurrencyRupee />} onClick={() => handlePayment()}>Pay Now</Button>
                    </Box>
                </Paper>
            </Modal >

            <Modal
                sx={{ overflowX: "hidden" }}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '0%',
                    left: '50%',
                    transform: 'translate(-50%)',
                    minWidth: 350,
                }}>
                    <BusinessBill date={date} associate={associateId} business={rows.filter((item) => item.status != 'cancelled')} />
                </Paper>
            </Modal>
        </>
    )
}

export default Payment