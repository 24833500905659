import { useMemo, useState, lazy, Suspense, useEffect } from "react";
import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { Close } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { SnackbarProvider, closeSnackbar } from 'notistack';
import axios from "axios";
import { themeSettings } from "./theme";
import { MyContextProvider } from "./components/global/MyContext";
import CircleLoading from "./components/global/circleloading";

import VisitReport from "./view/visit/visitreport";
import ViewCustomer from "./view/visit/viewcustomer";
import AllVisit from "./view/visit/allvisit";

import _404 from "./view/404"
import DualInvoice from "./components/invoice/dualinvoice";
import Payment from "./view/transaction/payment";
import BookingReport from "./view/bookingForm/bookingreport";

const Main = lazy(() => import('./view/main'));
const Home = lazy(() => import('./view/home'));
const AddAssociate = lazy(() => import('./view/associate/addassociate'));
const AssociateReport = lazy(() => import('./view/associate/associatereport'));
const EditAssociate = lazy(() => import('./view/associate/editassociate'));
const ViewTeam = lazy(() => import('./view/associate/viewteam'));

const AttendenceReport = lazy(() => import('./view/attendence/attendencereport'));
const AttendenceMonthReport = lazy(() => import('./view/attendence/attendencemonthreport'))

const AddEmployee = lazy(() => import('./view/employee/addemployee'));
const EmployeeReport = lazy(() => import('./view/employee/employeereport'));
const EditEmployee = lazy(() => import('./view/employee/editemployee'));

const AddProject = lazy(() => import('./view/project/addproject'));
const AddBlock = lazy(() => import('./view/project/addblock'));
const AddPlot = lazy(() => import('./view/project/addplot'));
const PlotReport = lazy(() => import('./view/project/plot-report'));
const EditProject = lazy(() => import('./view/project/edit-project'));

const Master = lazy(() => import('./view/bookingForm/master'));
const AllBooking = lazy(() => import('./view/bookingForm/allbooking'));

const Transaction = lazy(() => import('./view/transaction/transaction'));
const Business = lazy(() => import('./view/transaction/business'));

const ProjectReport = lazy(() => import('./view/report/projectreport'));

const Login = lazy(() => import('./view/login/login'));
const Loginotp = lazy(() => import('./view/login/loginotp'));
const PassVerify = lazy(() => import('./view/login/passverify'));
const Forgetotp = lazy(() => import('./view/login/forgetotp'));
const ForgetVerify = lazy(() => import('./view/login/forgetverify'));
const Reset = lazy(() => import('./view/login/reset'));

function App() {

  const [user, setUser] = useState({});
  const [role, setRole] = useState(null);
  const userId = useSelector((state) => state.global.userId);
  const theme = useMemo(() => createTheme(themeSettings()));
  const apiUrl = process.env.REACT_APP_API_URL;

  const auth = () => {
    axios.post(apiUrl + 'auth/auth')
      .then(function (response) {
        if (response.data.status) {
          setRole(response.data.data.role);
        }
        else {
          setRole(null);
        }
      })
      .catch(function (error) {
        setRole(null);
      });
  }

  useEffect(() => {
    auth();
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={4000}
          preventDuplicate={true}
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)}>
              <Close fontSize="small" sx={{ color: "#fff" }} />
            </IconButton>
          )}
        >
          <MyContextProvider value={{ apiUrl, role, user, setUser }}>

            <BrowserRouter>
              <Routes>
                {userId.payload == null && <Route path="/*" element={<Navigate to="/login" replace />} />}
                <Route exact path="/login" element={<Suspense fallback={<CircleLoading />}><Login setRole={setRole} /></Suspense>} />
                <Route exact path="/login-otp" element={<Suspense fallback={<CircleLoading />}><Loginotp /></Suspense>} />
                <Route exact path="/pass-verify" element={<Suspense fallback={<CircleLoading />}><PassVerify setRole={setRole} /></Suspense>} />
                <Route exact path="/forget" element={<Suspense fallback={<CircleLoading />}><Forgetotp /></Suspense>} />
                <Route exact path="/forget-verify" element={<Suspense fallback={<CircleLoading />}><ForgetVerify /></Suspense>} />
                <Route exact path="/reset" element={<Suspense fallback={<CircleLoading />}><Reset setRole={setRole} /></Suspense>} />
                {userId.payload !== null &&
                  <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><Main setRole={setRole} /></Suspense>} >
                    <Route exact path="/" element={<Suspense fallback={<CircleLoading />}><Home /></Suspense>} />
                    <Route exact path="add-associate" element={<Suspense fallback={<CircleLoading />}><AddAssociate /></Suspense>} />
                    <Route exact path="edit-associate" element={<Suspense fallback={<CircleLoading />}><EditAssociate /></Suspense>} />
                    <Route exact path="associate-report" element={<Suspense fallback={<CircleLoading />}><AssociateReport /></Suspense>} />
                    <Route exact path="view-team" element={<Suspense fallback={<CircleLoading />}><ViewTeam /></Suspense>} />
                    <Route exact path="add-employee" element={<Suspense fallback={<CircleLoading />}><AddEmployee /></Suspense>} />
                    <Route exact path="employee-report" element={<Suspense fallback={<CircleLoading />}><EmployeeReport /></Suspense>} />
                    <Route exact path="edit-employee" element={<Suspense fallback={<CircleLoading />}><EditEmployee /></Suspense>} />
                    <Route exact path="add-project" element={<Suspense fallback={<CircleLoading />}><AddProject /></Suspense>} />
                    <Route exact path="add-block" element={<Suspense fallback={<CircleLoading />}><AddBlock /></Suspense>} />
                    <Route exact path="add-plot" element={<Suspense fallback={<CircleLoading />}><AddPlot /></Suspense>} />
                    <Route exact path="plot-report" element={<Suspense fallback={<CircleLoading />}><PlotReport /></Suspense>} />
                    <Route exact path="edit-project" element={<Suspense fallback={<CircleLoading />}><EditProject /></Suspense>} />
                    <Route exact path="visit-report" element={<Suspense fallback={<CircleLoading />}><VisitReport /></Suspense>} />
                    <Route exact path="view-customer/:id" element={<Suspense fallback={<CircleLoading />}><ViewCustomer /></Suspense>} />
                    <Route exact path="all-visit/:id" element={<Suspense fallback={<CircleLoading />}><AllVisit /></Suspense>} />
                    <Route exact path="attendance" element={<Suspense fallback={<CircleLoading />}><AttendenceReport /></Suspense>} />
                    <Route exact path="attendance-report" element={<Suspense fallback={<CircleLoading />}><AttendenceMonthReport /></Suspense>} />
                    <Route exact path="booking" element={<Suspense fallback={<CircleLoading />}><Master /></Suspense>} />
                    <Route exact path="all-booking" element={<Suspense fallback={<CircleLoading />}><AllBooking /></Suspense>} />
                    <Route exact path="all-booking/:id" element={<Suspense fallback={<CircleLoading />}><BookingReport /></Suspense>} />
                    <Route exact path="transaction" element={<Suspense fallback={<CircleLoading />}><Transaction /></Suspense>} />
                    <Route exact path="business" element={<Suspense fallback={<CircleLoading />}><Business /></Suspense>} />
                    <Route exact path="payment" element={<Suspense fallback={<CircleLoading />}><Payment /></Suspense>} />
                    <Route exact path="invoice/:id" element={<Suspense fallback={<CircleLoading />}><DualInvoice /></Suspense>} />

                    <Route exact path="project-report" element={<Suspense fallback={<CircleLoading />}><ProjectReport /></Suspense>} />
                    <Route exact path="*" element={<Suspense fallback={<CircleLoading />}><_404 /></Suspense>} />

                  </Route>
                }
              </Routes>
            </BrowserRouter>
          </MyContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </div >
  );
}

export default App;
