import React, { useEffect, useState } from 'react'
import { Box, Paper, FormControl, OutlinedInput, InputAdornment, Button, Modal, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit, Print, Search } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import clsx from "clsx";
import dayjs from 'dayjs';
import Header from '../../components/header'
import { setHiddenColumn } from '../../store/globalSlice';
import { useMycontext } from '../../components/global/MyContext';
import CardBox from '../../components/cardbox';
import Visiting from '../../components/invoice/visiting';

const columns = [
    {
        field: 'id',
        headerName: 'S.No',
        filterable: false,
        width: 30,
    },
    {
        field: 'associate_id',
        headerName: 'Associate ID',
        minWidth: 120
    },
    {
        field: 'associate_full_name',
        headerName: 'Associate Name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        minWidth: 150,
        flex: 1,
        valueGetter: (params) =>
            `${params.row.first_name || ''} ${params.row.last_name || ''}`,
    },
    {
        field: 'full_name',
        headerName: 'Customer Name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        minWidth: 150,
        flex: 1,
    },
    {
        field: 'associate_mobile_no',
        headerName: 'Ass. Mobile No',
        minWidth: 120,
    },
    {
        field: 'visit_date',
        headerName: 'Visit Date',
        minWidth: 120,
        valueGetter: (params) =>
            `${dayjs(params.row.visit_date).format('DD-MMM-YYYY')}`,
    },
    {
        field: 'time_slot',
        headerName: 'Time Slot',
        minWidth: 120,
    },
    {
        field: 'no_of_per',
        headerName: 'Person',
        width: 80,
    },
    {
        field: 'created_at',
        headerName: 'Date Time',
        minWidth: 200,
        valueGetter: (params) =>
            `${dayjs(params.row.created_at).format('hh:mm A DD-MMM-YYYY')}`,
    },
];

const VisitReport = () => {

    const dispatch = useDispatch();
    const props = useOutletContext();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { apiUrl } = useMycontext();
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const hiddenColumn = useSelector((state) => state.global.hiddenColumn);
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState([]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        if (rows.filter((item) => item.visit_date == dayjs().format('YYYY-MM-DD')).length == 0) {
            enqueueSnackbar("No Visits for Today.", { variant: "error" });
            return;
        }
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    function debounce(fn, delay) {
        var timer = null;
        return function () {
            var context = this;
            var args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args);
            }, delay);
        };
    }

    const searchItems = async (searchtext) => {
        setLoading(true);
        axios.get(apiUrl + 'visiting/getvisitsearch?text=' + searchtext + '&date=' + date)
            .then(function (response) {
                if (response.data.status) {
                    setRows(response.data.data.map((row, index) => ({ ...row, id: index + 1 })));
                    setTotal(getBusinessData(response.data.data));
                }
                else {
                    setRows([]);
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }
    const debouncedSearch = debounce(searchItems, 1000);

    const getBusinessData = (data) => {
        let total = {
            "10:00AM": 0,
            "12:00PM": 0,
            "02:00PM": 0,
            "04:00PM": 0,
        };

        data.forEach((item) => {
            if (item.time_slot == "10:00 AM") {
                total['10:00AM'] = total['10:00AM'] + 1;
            }
            else if (item.time_slot == "12:00 PM") {
                total['12:00PM'] = total['12:00PM'] + 1;
            }
            else if (item.time_slot == "02:00 PM") {
                total['02:00PM'] = total['02:00PM'] + 1;
            }
            else if (item.time_slot == "04:00 PM") {
                total['04:00PM'] = total['04:00PM'] + 1;
            }
        });
        return total;
    }

    const getCustomerVisit = () => {
        setLoading(true);
        axios.get(apiUrl + 'visiting/getall?date=' + date)
            .then(function (response) {
                if (response.data.status) {
                    setRows(response.data.data.map((row, index) => ({ ...row, id: index + 1 })));
                    setTotal(getBusinessData(response.data.data));
                }
                else {
                    setRows([]);
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        getCustomerVisit();
    }, [date]);

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px" }}>
                    <Box sx={{ flex: 1, display: "flex", gap: "10px", textAlign: "center" }}>
                        <CardBox title="10:00 AM" number={total['10:00AM'] || "0"} subtitle={date == dayjs().format('YYYY-MM-DD') ? "Today's Visit" : date == '' ? "All Visit" : "Visit of - " + dayjs(date).format('DD-MMM-YYYY')} bgColor="#E8F0FB" txtColor="#3786F1" />
                        <CardBox title="12:00 PM" number={total['12:00PM'] || "0"} subtitle={date == dayjs().format('YYYY-MM-DD') ? "Today's Visit" : date == '' ? "All Visit" : "Visit of - " + dayjs(date).format('DD-MMM-YYYY')} bgColor="#F3E8FB" txtColor="#ba68c8" />
                    </Box>
                    <Box sx={{ flex: 1, display: "flex", gap: "10px", textAlign: "center" }}>
                        <CardBox title="02:00 PM" number={total['02:00PM'] || "0"} subtitle={date == dayjs().format('YYYY-MM-DD') ? "Today's Visit" : date == '' ? "All Visit" : "Visit of - " + dayjs(date).format('DD-MMM-YYYY')} bgColor="#EBFDED" txtColor="#2e993b" />
                        <CardBox title="04:00 PM" number={total['04:00PM'] || "0"} subtitle={date == dayjs().format('YYYY-MM-DD') ? "Today's Visit" : date == '' ? "All Visit" : "Visit of - " + dayjs(date).format('DD-MMM-YYYY')} bgColor="#FFE7E7" txtColor="#ef5350" />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: { xs: "", md: "center" }, gap: "10px", marginTop: "10px", flexDirection: { xs: "column", md: "row" } }}>
                    <Header title="Visitor's Reports" />
                    <Box sx={{ display: "flex", gap: "10px", flexDirection: { xs: "column-reverse", md: "row" } }}>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                            <TextField
                                sx={{ flex: 1 }}
                                size='small'
                                variant="outlined"
                                type="date"
                                onChange={(e) => setDate(e.target.value)}
                                value={date}
                                name="visit_date"
                            />
                            <Button size='small' variant='contained' startIcon={<Print />} onClick={handleOpen}>Print Report</Button>
                        </Box>
                        <FormControl sx={{ width: { xs: "100%", md: "32ch" } }} variant="outlined">
                            <OutlinedInput
                                sx={{ height: "40px" }}
                                placeholder="Search..."
                                onChange={(e) => debouncedSearch(e.target.value)}
                                id="outlined-adornment-weight"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                }
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                    "aria-label": "weight"
                                }}
                            />
                        </FormControl>
                    </Box>
                </Box >
                <Box sx={{
                    height: { xs: "calc(100vh - 100px)", md: "calc(100vh - 285px)" },
                    width: { xs: "calc(100vw - 25px)", md: props.collapsed ? "calc(100vw - 105px)" : "calc(100vw - 270px)" },
                    marginTop: "10px",
                    "& .super-app.active": {
                        "& .MuiDataGrid-cellContent": {
                            textAlign: "center",
                            minWidth: "80px",
                            border: "solid 1px green",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            color: "#fff",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                            backgroundColor: "green"
                        }
                    },
                    "& .super-app.deactive": {
                        "& .MuiDataGrid-cellContent": {
                            textAlign: "center",
                            minWidth: "80px",
                            border: "solid 1px orange",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            color: "#fff",
                            backgroundColor: "red"
                        }
                    },
                    "& .super-app.gold": {
                        "& .MuiDataGrid-cellContent": {
                            textAlign: "center",
                            minWidth: "80px",
                            border: "solid 1px orange",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            color: "#000",
                            fontFamily: "Poppins",
                            backgroundColor: "#ffb319"
                        }
                    },
                    "& .super-app.diamond": {
                        "& .MuiDataGrid-cellContent": {
                            textAlign: "center",
                            minWidth: "80px",
                            border: "solid 1px skyblue",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            color: "#000",
                            fontWeight: "500",
                            backgroundColor: "#9cf8ff"
                        }
                    },
                }}>
                    <DataGrid
                        loading={loading}
                        rows={rows}
                        columns={columns}
                        onPaginationModelChange={(params) => setPage(params.page)}
                        hideFooterSelectedRowCount={true}
                        pageSizeOptions={[100]}
                        initialState={{
                            columns: {
                                columnVisibilityModel: hiddenColumn.payload
                            },
                        }}
                        hideFooter={true}
                        onRowClick={(e) => {
                            navigate('/view-customer/' + e.row.associate_id)
                        }}
                        onColumnVisibilityModelChange={(params) => { dispatch(setHiddenColumn(params)) }}
                        rowCount={rowCount}
                        paginationMode="server"
                    />
                </Box>
            </Box >

            <Modal
                sx={{ overflowX: "hidden" }}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '0%',
                    left: '50%',
                    transform: 'translate(-50%)',
                }}>

                    <Visiting visitors={rows.filter((item) => item.visit_date == dayjs().format('YYYY-MM-DD'))} />
                </Paper>
            </Modal>
        </>
    )
}

export default VisitReport