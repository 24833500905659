import { Box, Paper, Button, Typography, IconButton, Popover, TextField, Modal, FormHelperText, Menu, FormControl, Autocomplete } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { useMycontext } from '../../components/global/MyContext';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom'
import { CloudUpload, Delete, Edit, MoreVert, Visibility } from '@mui/icons-material';
import { MuiFileInput } from 'mui-file-input'

const BookingReport = () => {

    const { id } = useParams();
    const { apiUrl } = useMycontext();
    const { enqueueSnackbar } = useSnackbar();
    const props = useOutletContext();
    const [loading, setLoading] = useState(true);
    const [subtotal, setSubTotal] = useState("");
    const [booking, setBooking] = useState([]);
    const [transaction, setTransaction] = useState([]);
    const [doc, setDoc] = useState([]);
    const componentRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [associate, setAssociate] = useState([]);
    const [supporterId, setSupporterId] = useState({});
    const [supporterId2, setSupporterId2] = useState({});

    const [value, setValue] = useState(null)
    const [fileName, setFileName] = useState('')
    const [error, setError] = useState(false)

    const handleChange = (newValue) => {
        setValue(newValue);
        setError(false);
    }

    const [eopen, setEOpen] = useState(false);
    const handleEOpen = () => setEOpen(true);
    const handleEClose = () => setEOpen(false);

    const [mopen, setMOpen] = useState(false);
    const handleMOpen = () => setMOpen(true);
    const handleMClose = () => setMOpen(false);

    const handleClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setSelectedItem(item);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popid = open ? 'simple-popover' : undefined;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getAssociates = () => {
        setLoading(true);
        axios.get(apiUrl + "associate/getall?offset=0")
            .then(function (response) {
                if (response.data.status) {
                    setAssociate(response.data.data);
                    getBooking(response.data.data);
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const findAssociate = (ass, associateId) => {
        const option = ass.find(item => item.associate_id === associateId);
        return option ? option : null;
    };

    const getBooking = (ass) => {
        setLoading(true);
        axios.get(apiUrl + 'booking/get?id=' + id)
            .then(function (response) {
                if (response.data.status) {
                    setBooking(response.data.data);
                    setSupporterId(findAssociate(ass, response.data.data?.supporter_id));
                    setSupporterId2(findAssociate(ass, response.data.data?.supporter_id2));
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const updateSupporter = () => {
        const formData = new FormData();
        formData.append('booking_id', id);
        formData.append('supporter_id', supporterId.associate_id);
        formData.append('supporter_id2', supporterId2.associate_id);
        setLoading(true);
        axios.post(apiUrl + 'booking/updatesupporter', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    handleEClose();
                    getBooking();
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const getBookingTransaction = () => {
        setLoading(true);
        axios.get(apiUrl + 'transaction/get?id=' + id)
            .then(function (response) {
                if (response.data.status) {
                    setTransaction(response.data.data.filter((item) => item.status != 'cancelled'));
                    let result = 0;
                    response.data.data.map((item) => {
                        if (item.status == 'success') {
                            result = result + parseFloat(item.paid_amount);
                        }

                    })
                    setSubTotal(result);
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                    setTransaction([]);
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const uploadDoc = () => {
        if (value == null) {
            setError(true);
            return;
        }
        const formData = new FormData();
        if (value.file !== null) {
            formData.append('id', id);
            formData.append('document', value, value.name);
            formData.append('filename', fileName);
        }
        else {
            enqueueSnackbar("Please Select a Valid file.", { variant: "error" });
        }
        setLoading(true);
        axios.post(apiUrl + 'booking/uploaddoc', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    setLoading(false);
                    getDocFiles();
                    setValue(null);
                    setFileName('');
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                    setLoading(false);
                }
                handleMClose();
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }

    const getDocFiles = () => {
        axios.get(apiUrl + 'booking/getdoc?id=' + id)
            .then(function (response) {
                if (response.data.status) {
                    setDoc(response.data.data);
                }
                else {
                    setDoc([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleDownload = (filename) => {
        let filePath = "https://dashboard.smartrems.com" + apiUrl + "assets/booking-doc/" + id + "/" + filename;
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = filePath;
        link.setAttribute('download', '');

        // Append the anchor element to the body
        document.body.appendChild(link);

        // Trigger the click event on the anchor element
        link.click();

        // Remove the anchor element from the body
        document.body.removeChild(link);
    };

    const handleDelete = (file) => {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('filename', file);
        axios.post(apiUrl + 'booking/deletedoc', formData)
            .then(function (response) {
                if (response.data.status) {
                    enqueueSnackbar(response.data.message, { variant: "success" });
                    handleClose();
                    getDocFiles();
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
        getAssociates();
        getBookingTransaction();
        getDocFiles();
    }, [])

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px" }}>
                <Box sx={{ flex: 1 }}>
                    <Typography variant='h4'>1. Customer Details</Typography>
                    <Paper sx={{ padding: "10px 15px", marginTop: "10px" }}>
                        <Box sx={{ display: 'flex', alignItems: "center" }}>
                            <Typography sx={{ flex: 1 }} variant='h6' color='primary'>Personal Information :-</Typography>
                            <Button size='small' sx={{ height: "20px" }} startIcon={<Edit fontSize='small' />} onClick={handleEOpen}>Edit Service Provider</Button>
                        </Box>
                        <Box sx={{ display: "flex", gap: '5px', marginBottom: '10px' }}>
                            <Box sx={{ color: "#909090", minWidth: "100px" }}>
                                <Typography variant='h6'>Name </Typography>
                                <Typography variant='h6'>Father's&nbsp;Name </Typography>
                                <Typography variant='h6'>Occupation </Typography>
                                <Typography variant='h6'>Date of birth </Typography>
                                <Typography variant='h6'>Age </Typography>
                                <Typography variant='h6'>Mobile No </Typography>
                                <Typography variant='h6'>Email </Typography>
                                <Typography variant='h6'>Pan No </Typography>
                                <Typography variant='h6'>Pincode </Typography>
                                <Typography variant='h6'>City </Typography>
                                <Typography variant='h6'>State </Typography>
                                <Typography variant='h6'>Address </Typography>

                            </Box>
                            <Box>
                                <Typography variant='h6'>- {booking.customer_first_name + " " + booking.customer_last_name}</Typography>
                                <Typography variant='h6'>- {booking.father_first_name + " " + booking.father_last_name}</Typography>
                                <Typography variant='h6'>- {booking.occupation}</Typography>
                                <Typography variant='h6'>- {dayjs(booking.date_of_birth).format('DD-MMM-YYYY')}</Typography>
                                <Typography variant='h6'>- {booking.age}</Typography>
                                <Typography variant='h6'>- {booking.mobile_no}</Typography>
                                <Typography variant='h6'>- {booking.email}</Typography>
                                <Typography variant='h6'>- {booking.pan_no}</Typography>
                                <Typography variant='h6'>- {booking.pincode}</Typography>
                                <Typography variant='h6'>- {booking.city}</Typography>
                                <Typography variant='h6'>- {booking.state}</Typography>
                                <Typography variant='h6'>- {booking.address}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ flex: 1 }} variant='h6' color='primary'>Nominee Information :-</Typography>
                        <Box sx={{ display: "flex", gap: '5px', marginBottom: '10px' }}>
                            <Box sx={{ color: "#909090", minWidth: "100px" }}>
                                <Typography variant='h6'>Name </Typography>
                                <Typography variant='h6'>Age </Typography>
                                <Typography variant='h6'>Relation </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h6'>- {booking.nominee_first_name}</Typography>
                                <Typography variant='h6'>- {booking.nominee_age}</Typography>
                                <Typography variant='h6'>- {booking.relation}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ flex: 1 }} variant='h6' color='primary'>Associate and Sponsor Details :-</Typography>
                        <Box sx={{ display: "flex", gap: '5px' }}>
                            <Box sx={{ color: "#909090", minWidth: "100px" }}>
                                <Typography variant='h6'>Associate Name </Typography>
                                <Typography variant='h6'>Service Provider </Typography>
                                <Typography variant='h6'>Service Provider 2 </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h6'>- {booking?.first_name + " " + booking?.last_name} : {booking?.associate_id}</Typography>
                                <Typography variant='h6'>- {supporterId?.first_name + " " + supporterId?.last_name} : {supporterId?.associate_id}</Typography>
                                <Typography variant='h6'>- {supporterId2?.first_name + " " + supporterId2?.last_name} : {supporterId2?.associate_id}</Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
                <Box sx={{ flex: .6 }}>
                    <Typography variant='h4'>2. Project Details</Typography>
                    <Paper sx={{ padding: "10px 15px", marginTop: "10px" }}>
                        <Typography sx={{ flex: 1 }} variant='h6' color='primary'>Plot Details :-</Typography>
                        <Typography variant='h5'>{booking.project_name}</Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box>
                                <Typography variant='h6'>Block &nbsp;&nbsp;&nbsp;- <Typography variant='span' color='primary'>{booking.block_name}</Typography></Typography>
                                <Typography variant='h6'>Plot No - <Typography variant='span' color='primary'>{booking.pn_no}</Typography></Typography>
                                <Typography variant='h6'>Tehsil&nbsp;&nbsp;&nbsp;- <Typography variant='span' color='primary'>{booking.tehsil}</Typography></Typography>
                                <Typography variant='h6'>District - <Typography variant='span' color='primary'>{booking.district}</Typography></Typography>
                            </Box>
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant='h6' color='primary'>Area</Typography>
                                <Typography variant='h6'>{booking.landarea} (sqft)</Typography>
                                <Typography variant='h6' color='primary'>Dimention</Typography>
                                <Typography variant='h6'>{booking.dimension}</Typography>
                            </Box>
                        </Box>
                        <Typography variant='h6'>Details - {booking.details}</Typography>
                        <Typography sx={{ flex: 1, marginTop: '10px' }} variant='h6' color='primary'>Booking Details :-</Typography>
                        <Box sx={{ display: "flex", gap: '5px', marginBottom: '10px' }}>
                            <Box sx={{ color: "#909090", minWidth: "100px" }}>
                                <Typography variant='h6'>Booking No </Typography>
                                <Typography variant='h6'>Booking ID </Typography>
                                <Typography variant='h6'>Booking Date </Typography>
                                <Typography variant='h6'>Agreement Date </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h6'>- &nbsp; {booking.booking_no}</Typography>
                                <Typography variant='h6'>- &nbsp; {booking.id}</Typography>
                                <Typography variant='h6'>- &nbsp; {dayjs(booking.booking_date).format('DD-MMM-YYYY')}</Typography>
                                <Typography variant='h6'>- &nbsp; {booking.agreement ? dayjs(booking.agreement).format('DD-MMM-YYYY | hh:MM A') : "Not Created"}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ flex: 1, marginTop: '10px' }} variant='h6' color='primary'>Payment Information :-</Typography>
                        <Box sx={{ display: "flex", gap: '5px' }}>
                            <Box sx={{ color: "#909090", minWidth: "100px" }}>
                                <Typography variant='h6'>Mode of Booking </Typography>
                                <Typography variant='h6'>Payment Mode</Typography>
                                <Typography variant='h6'>Rate</Typography>
                                <Typography variant='h6'>Total Amount </Typography>
                                <Typography variant='h6'>Booking Amount</Typography>
                                <Typography variant='h6'>Expendature</Typography>
                                <Typography variant='h6'>Discount</Typography>
                                <Typography variant='h6'>Final Amount</Typography>
                            </Box>
                            <Box>
                                <Typography variant='h6'>- &nbsp; {booking.booking_mode}</Typography>
                                <Typography variant='h6'>- &nbsp; {booking.payment_mode}</Typography>
                                <Typography variant='h6'>- &nbsp; {booking.rate}</Typography>
                                <Typography variant='h6'>- &nbsp; {parseFloat(booking.total_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                                <Typography variant='h6'>- &nbsp; {parseFloat(booking.booking_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                                <Typography variant='h6'>- &nbsp; {parseFloat(booking.expenditure_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                                <Typography variant='h6'>- &nbsp; {parseFloat(booking.discount_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                                <Typography variant='h6'>- &nbsp; {parseFloat(booking.final_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Box>
            <Box>
                <Box sx={{ display: "flex", justifyContent: 'space-between', margin: "10px", height: '30px' }}>
                    <Typography variant='h4'>3. Uploaded Document</Typography>
                    <Button size='small' component="label" variant="contained" onClick={handleMOpen} startIcon={<CloudUpload />}>
                        Upload file
                    </Button>
                </Box>
                <Box sx={{ marginTop: "10px", display: "flex", gap: "10px", flexWrap: "wrap" }}>
                    {doc.map((item, index) => {
                        return (
                            <Paper key={index} sx={{ padding: "10px", display: "flex", alignItems: "center", gap: "10px" }}>
                                <Typography>{`${index + 1}. ${item.split('.')[0]}`}</Typography>
                                <Button size='small' variant='outlined' onClick={() => handleDownload(item)} startIcon={<Visibility />}>View File</Button>
                                <IconButton sx={{ padding: "5px" }} onClick={(event) => handleClick(event, item)}><MoreVert fontSize='small' /></IconButton>
                            </Paper>
                        )
                    })}
                    <Popover
                        id={popid}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Paper sx={{ padding: "10px" }}>
                            <Button variant='contained' size='small' color='error' onClick={() => handleDelete(selectedItem)} startIcon={<Delete />}>Delete</Button>
                        </Paper>
                    </Popover>
                </Box>
                <Paper sx={{ padding: "10px", marginTop: "10px" }}>
                    <Box>
                        <Box>
                            <Typography variant='h4' sx={{ textAlign: 'center', marginBottom: "10px" }}>4. Payment Details</Typography>
                        </Box>
                        <Box sx={{ width: { xs: "calc(100vw - 50px)", md: props.collapsed ? "calc(100vw - 125px)" : "calc(100vw - 295px)" }, overflowY: "hidden" }}>
                            <TableContainer component={Box} sx={{ width: "100%" }}>
                                <Table sx={{ border: "solid 1px #cfcfcf" }} size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: "600" }}>S.No</TableCell>
                                            <TableCell sx={{ fontWeight: "600" }}>Date</TableCell>
                                            <TableCell sx={{ fontWeight: "600" }} align='center'>Mode</TableCell>
                                            <TableCell sx={{ fontWeight: "600" }} align='center'>Payment Mode</TableCell>
                                            <TableCell sx={{ fontWeight: "600" }} align='center'>Status</TableCell>
                                            <TableCell sx={{ fontWeight: "600" }} align="right">Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transaction.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}.</TableCell>
                                                <TableCell>{dayjs(item?.txn_date).format('DD/MMM/YYYY')}</TableCell>
                                                <TableCell align='center'>{item?.remark}</TableCell>
                                                <TableCell align='center'>{item?.payment_mode}</TableCell>
                                                <TableCell align='center' sx={{ textTransform: "capitalize" }}>{item.status}</TableCell>
                                                <TableCell align="right">{parseFloat(item?.paid_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={{ maxWidth: "100%", display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ minWidth: { xs: "calc(100vw - 90%)", md: "calc(100vw - 80%)", lg: "calc(100vw - 60%)" } }}></Box>
                        <TableContainer component={Box}>
                            <Table sx={{ minWidth: "50%", border: "solid 1px #cfcfcf", borderTop: "none" }} aria- label="simple table" size='small'>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "600" }}>Total Paid Amount</TableCell>
                                    <TableCell align="right">{parseFloat(subtotal).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "600" }}>Remaining Amount</TableCell>
                                    <TableCell align="right">{parseFloat(booking.final_amount - subtotal).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                                </TableRow>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper >
            </Box >

            <Modal
                open={eopen}
                onClose={handleEClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 600,
                    minWidth: 650,
                    p: 4,
                }}>

                    <Box>
                        <FormControl fullWidth variant='standard'>
                            <Autocomplete
                                fullWidth
                                autoComplete
                                disableClearable
                                size='small'
                                value={`${supporterId?.first_name} ${supporterId?.last_name} : ${supporterId?.associate_id}`}
                                onChange={(event, newValue) => {
                                    setSupporterId(newValue);
                                }}
                                options={associate.map(option => ({ ...option, label: `${option.first_name} ${option.last_name} : ${option.associate_id}` }))}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        variant="standard"
                                        sx={{ textTransform: "capitalize" }}
                                        label="Proposer Associate ID"
                                    />}
                            />
                        </FormControl>
                    </Box>

                    <Box sx={{ mt: 2, mb: 2 }}>
                        <FormControl fullWidth variant='standard'>
                            <Autocomplete
                                fullWidth
                                autoComplete
                                disableClearable
                                size='small'
                                value={`${supporterId2?.first_name} ${supporterId2?.last_name} : ${supporterId2?.associate_id}`}
                                onChange={(event, newValue) => {
                                    setSupporterId2(newValue);
                                }}
                                options={associate.map(option => ({ ...option, label: `${option.first_name} ${option.last_name} : ${option.associate_id}` }))}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        variant="standard"
                                        sx={{ textTransform: "capitalize" }}
                                        label="Proposer Associate ID 2"
                                    />}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: "center" }}>
                        <Button
                            sx={{ borderRadius: "5px", margin: "0px", height: "40px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => updateSupporter()}
                        >Update Supporter</Button>
                    </Box>

                </Paper>
            </Modal >

            <Modal
                open={mopen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 600,
                    minWidth: 350,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Enter file name of document.
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "10px", marginTop: "10px" }}>
                        <TextField
                            size='small'
                            variant="outlined"
                            type="text"
                            label="File Name"
                            onChange={(e) => setFileName(e.target.value)}
                            value={fileName}
                            name="filename"
                        />
                        <Box sx={{ flex: 1 }}>
                            <MuiFileInput size='small' placeholder='Attache a document' value={value} onChange={handleChange} />
                        </Box>
                    </Box>
                    {error && <FormHelperText error={error}>Please Enter file name and attached a file to upload.</FormHelperText>}
                    <Box sx={{ display: "flex", gap: '10px', marginTop: "20px" }}>
                        <Button
                            fullWidth size="large"
                            sx={{ borderRadius: "5px", margin: "0px", height: "40px" }}
                            variant="contained"
                            color="error"
                            onClick={handleMClose}
                        >No</Button>
                        <Button
                            fullWidth size="large"
                            sx={{ borderRadius: "5px", margin: "0px", height: "40px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => uploadDoc()}
                        >Yes</Button>
                    </Box>
                </Paper>
            </Modal>
        </>
    )
}

export default BookingReport