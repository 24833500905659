import React, { useEffect, useState } from 'react'
import Header from '../../components/header'
import { Paper, Box, Avatar, Typography, FormControl, OutlinedInput, InputAdornment, Button, Modal, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import axios from 'axios';
import { useMycontext } from '../../components/global/MyContext';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

const ViewCustomer = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const { apiUrl } = useMycontext();
    const [customer, setCustomer] = useState([]);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const getAll = () => {
        axios.get(apiUrl + 'customer/getall?id=' + id)
            .then(function (response) {
                if (response.data.status) {
                    setCustomer(response.data.data);
                }
                else {
                    setCustomer([]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getAll();
    }, [])

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, gap: "10px", alignItems: "center" }}>
                <Header title="All Customers" />
                <FormControl sx={{ width: { xs: "100%", md: "40ch" } }} variant="outlined">
                    <OutlinedInput
                        sx={{ height: "35px" }}
                        placeholder="Search..."
                        onChange={(e) => ""}
                        id="outlined-adornment-weight"
                        endAdornment={
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            "aria-label": "weight"
                        }}
                    />
                </FormControl>
            </Box>
            <Box sx={{ padding: "10px 0px", display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {customer.map((item) => {
                    return (
                        <Paper sx={{ padding: { xs: "10px", md: '15px' }, display: "flex", justifyContent: "space-between", alignItems: 'center', gap: "0px", width: { xs: "100%", sm: "100%", md: "calc(50% - 5px)" } }} onClick={() => { navigate('/all-visit/' + item.customer_id) }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center', gap: "10px" }}>
                                <Avatar alt="Profile" src={apiUrl + "assets/customer-photos/" + item.customer_id + ".png"}
                                    sx={{
                                        width: { xs: "130px", md: "150px" },
                                        height: { xs: "130px", md: "150px" },
                                        marginBottom: "0px",
                                        borderRadius: "10px"
                                    }}
                                >Upload Photo</Avatar>
                                <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <Box sx={{ display: { xs: "none", sm: "none", md: "flex" }, flexDirection: "column", gap: "5px" }}>
                                        <Typography variant='h6'>Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- </Typography>
                                        <Typography variant='h6'>Gender &nbsp;&nbsp;&nbsp;-</Typography>
                                        <Typography variant='h6'>Mobile &nbsp;&nbsp;&nbsp;&nbsp;-</Typography>
                                        <Typography variant='h6'>Add Date-</Typography>
                                        <Typography variant='h6'>Address &nbsp;-</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                        <Typography variant='h6' sx={{ textTransform: "capitalize" }}>{item.first_name + " " + item.last_name}</Typography>
                                        <Typography variant='h6' sx={{ textTransform: "capitalize" }}>{item.gender}</Typography>
                                        <Typography variant='h6' sx={{ textTransform: "capitalize" }}>{item.mobile_no}</Typography>
                                        <Typography variant='h6' sx={{ textTransform: "capitalize" }}>{dayjs(item.created_at).format('DD-MMM-YYYY')}</Typography>
                                        <Typography variant='h6' sx={{ textTransform: "capitalize" }}>{`${item.address} ${item.city}, ${item.state}`}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box sx={{ display: { xs: "none", md: "flex" }, flexDirection: "column", gap: "20px" }}>
                                <Button sx={{ flex: .9 }} size='small' variant='contained' color='warning' startIcon={<Edit />} onClick={(event) => { goToEdit(item.customer_id); event.stopPropagation() }}>Edit</Button>
                                <Button sx={{ flex: 1.2 }} size='small' variant='contained' color='error' startIcon={<Delete />} onClick={(event) => { handleOpen(item.customer_id); event.stopPropagation() }}>Delete</Button>
                            </Box>
                            <Box sx={{ display: { xs: "flex", md: "none" }, flexDirection: "column", gap: "5px" }}>
                                <IconButton sx={{ flex: .9, padding: "5px" }} color='warning' onClick={(event) => { goToEdit(item.customer_id); event.stopPropagation() }}><Edit /></IconButton>
                                <IconButton sx={{ flex: 1.2, padding: "5px" }} color='error' onClick={(event) => { handleOpen(item.customer_id); event.stopPropagation() }}><Delete /></IconButton>
                            </Box> */}
                        </Paper>
                    )
                })}
            </Box>
        </>
    )
}

export default ViewCustomer